const appState = {
    user: null,
    sidebarShow: true,
    communityData : null,
    reportData: null,
    eventData: null,
    bannedUserData: null,
    accessToken: null,
    refreshToken: null,
}

const app = (state = {...appState}, action) => {
    switch (action.type) {
        case 'set':
            return { ...state, ...action }
        default:
            return state
    }
};


export default app
